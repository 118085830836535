/* eslint-disable import/first */
import React from "react";

import async from "../components/Async";

import { Monitor, Sliders, Users } from "react-feather";
// Auth components
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Landing
import Landing from "../pages/presentation/Landing";
import AuthGuard from "../components/AuthGuard";
import ProtectedPage from "../pages/protected/ProtectedPage";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Dashboards components
const Default = async(() => import("../pages/tables/SimpleTable1"));
const Default2 = async(() => import("../pages/tables/SimpleTable2"));
const Default3 = async(() => import("../pages/tables/ExtremeGrid"));
const Default4 = async(() => import("../pages/tables/GridTable"));

// Protected routes
// This route is only visible while signed in
const protectedPageRoutes = {
  id: "Private",
  path: "/private",
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

const dashboardsRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  header: "Pages",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: "/dashboard/default",
      name: "Default1",
      component: Default,
    },
    {
      path: "/dashboard/analytics",
      name: "Default2",
      component: Default2,
    },
    {
      path: "/dashboard/defaul3",
      name: "Default3",
      component: Default3,
    },
    {
      path: "/dashboard/defaul4",
      name: "Default4",
      component: Default4,
    },
  ],
  component: null,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [dashboardsRoutes];

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes];

// Routes visible in the sidebar
export const sidebarRoutes = [dashboardsRoutes];
